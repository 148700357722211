import React from "react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";

import FlexColumnCenter from "../../../layout/FlexColumnCenter";
import FlexColumn from "../../../layout/FlexColumn";
import FlexRowCenter from "../../../layout/FlexRowCenter";
import FadeInBottom from "../../../common/FadeInBottom";
import Location from "./Location";
import TitleWithBorderBlack from "../../../common/TItleWithBorderBlack";
import FlexRow from "../../../layout/FlexRow";
import AboutLogo from "./AboutLogo";
import Spacer from "../../../layout/Spacer";

const Company = () => {
  return (
    <Wrapper>
      <Spacer height="min(14.028vw, 202px)" />

      <FlexColumnCenter className="title" gap="min(53px, 3.681vw)">
        <StaticImage
          placeholder="none"
          id="company"
          src="../../../../images/company/Company.webp"
          alt="Company text"
          quality={100}
          style={{ width: "29.028vw", maxWidth: "418px" }}
        />
        <p className="title-text">
          日本とインドの2拠点にオフィスを構え、グローバルに事業を展開します
        </p>
      </FlexColumnCenter>
      <Spacer height="min(170px, 11.806vw)" />
      <FlexColumn gap="min(6.389vw, 92px)">
        <TitleWithBorderBlack title="会社概要" />
        <div className="ceo-wrapper">
          <FlexRow className="ceo" gap="min(7.5vw, 108px)">
            <FadeInBottom>
              <StaticImage
                className="ceo-image"
                src="../../../../images/company/company_ceo.webp"
                alt="company ceo"
                quality={100}
                placeholder="none"
              />
            </FadeInBottom>
            <FlexColumn className="ceo-text">
              <p className="ceo-text-title">代表取締役</p>
              <p className="ceo-text-name-jpn">クマール　ラトネッシュ</p>
              <p className="ceo-text-name-en">Ratnesh Kumar</p>
              <p className="ceo-text-career">
                名古屋生まれ名古屋育ち
                <br />
                愛知県立千種高等学校卒。慶應SFC卒。
                <br />
                10代から途上国開発に関心を持ち、在学中にオンライン寄付
                <br />
                システムの特許を取得しソーシャルビジネスの事業化を経験
                <br />
                大学卒業後は総合商社にて穀物取引・物流、事業投資先管理に従事
                <br />
                独立後、国内外の中小企業の事業再生事業に着手
                <br />
                その後、インドにて製造業、建設業を行う日系企業の経営経験を経て、
                <br />
                アイティップスを創業
              </p>
            </FlexColumn>
          </FlexRow>
        </div>
      </FlexColumn>

      <Spacer height="min(73px, 5.069vw)" />
      <FlexRowCenter className="about" gap="min(93px, 6.458vw)">
        <div className="about-left">
          <StaticImage
            placeholder="none"
            className="about-logoIMG"
            src="../../../../images/company/logo_overview.svg"
            alt="iTips Logo"
            quality={100}
            style={{ width: "min(323px, 22.431vw)" }}
          />

          <FlexColumn className="about-info">
            <p className="info-head">会社概要</p>
            <table className="company-info-table">
              <tr>
                <td className="table-column-1">商号</td>
                <td className="table-column-2">
                  <a href="https://itips.blue/" target="_blank">
                    アイティップス株式会社
                  </a>
                </td>
              </tr>
              <tr>
                <td className="table-column-1">設立年</td>
                <td className="table-column-2">2022年1月</td>
              </tr>
              <tr>
                <td className="table-column-1">所在地</td>
                <td className="table-column-2">
                  愛知県名古屋市昭和区鶴舞1丁目2-32
                </td>
              </tr>
              <tr className="space"></tr>
              <tr>
                <td className="table-column-1">代表者</td>
                <td className="table-column-2">
                  代表取締役　クマール　ラトネッシュ
                </td>
              </tr>
              <tr>
                <td className="table-column-1"></td>
                <td className="table-column-2">取締役　長尾 雅詞</td>
              </tr>
              <tr>
                <td className="table-column-1">海外子会社</td>
                <td className="table-column-2">iTips Private Limited</td>
              </tr>
              {/* 
             <tr>
                <td className="table-column-1">取引銀行</td>
                <td className="table-column-2">日本政策金融公庫</td>
              </tr>
*/}
            </table>
          </FlexColumn>
        </div>
        <FadeInBottom>
          <div className="about-logo">
            <FlexColumn
              className="about-logo-container"
              gap="min(22px, 1.528vw)"
            >
              <AboutLogo title="藍（あい）" LogoText="i">
                <p>
                  「青は藍（あい）より出でて藍（あい）より青し」
                  <br />
                  学問や努力により持って生まれた資質を越えること
                </p>
              </AboutLogo>
              <AboutLogo title="Tenacity" LogoText="T">
                <p>不屈の精神で挑むこと</p>
              </AboutLogo>
              <AboutLogo title="Initiative" LogoText="i">
                <p>
                  課題解決に向け、
                  <br />
                  自ら率先して取り組むこと
                </p>
              </AboutLogo>
              <AboutLogo title="Passion" LogoText="p">
                <p>
                  情熱と愛着をもって取り組み、
                  <br />
                  共に楽しむこと
                </p>
              </AboutLogo>
              <AboutLogo title="職人" LogoText="s">
                <p>
                  次世代に『職人気質（かたぎ）』を
                  <br />
                  継承すること
                </p>
              </AboutLogo>
            </FlexColumn>
          </div>
        </FadeInBottom>
      </FlexRowCenter>

      <Spacer height="min(158px, 10.972vw)" />

      <TitleWithBorderBlack title="所在地" />
      <Spacer height="min(158px, 10.972vw)" />
      <FlexColumn gap="min(17.778vw, 256px)">
        <FadeInBottom>
          <Location
            title="Nagoya, Japan Office"
            paragraph={"〒466-0064\n愛知県名古屋市昭和区鶴舞1丁目2-32"}
            image={
              <StaticImage
                placeholder="none"
                //src="../../../../images/company/company_location_image.webp"
                src="../../../../images/company/station_ai_4F.webp"
                alt="company location image"
                quality={100}
              />
            }
            locationURL="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.153812078738!2d136.91587687689056!3d35.152784572762286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600371006969dc3f%3A0x5fbab00be497ca28!2sSTATION%20Ai!5e0!3m2!1sja!2sjp!4v1729841678119!5m2!1sja!2sjp"
            id="japan"
          />
        </FadeInBottom>

        <FadeInBottom>
          <Location
            title="Registered Office, India"
            paragraph={
              "iTips Private Limited\n WeWork Galaxy, 43,\n Residency Rd, Shanthala Nagar, Ashok Nagar,\n Bengaluru, Karnataka, India 560025"
            }
            locationURL="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9764100503526!2d77.60618651072411!3d12.973360587289362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1680b3bd0f7b%3A0x5466660c68f2146b!2sWeWork!5e0!3m2!1sja!2sjp!4v1685065992088!5m2!1sja!2sjp"
            id="india"
          />
        </FadeInBottom>
        <FadeInBottom>
          <Location
            title="Factory & Skill Training Centre, India"
            paragraph={
              "iTips Private Limited\n No 90, Sipcot Industrial complex, Phase – 1,\n Hosur, Tamil Nadu, India 635126"
            }
            locationURL="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15567.398366247855!2d77.85016337597403!3d12.723233513029074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae776dcb134565%3A0x7daaf1286256e044!2sSIPCOT%20Industrial%20Complex%2C%20Sipcot%20Ph%203rd%20Cross%20Rd%2C%20Sipcot%20Phase%20II%20Industrial%20Complex%2C%20Sipcot%20Ph%202%2C%20Moranapalli%2C%20Tamil%20Nadu%20635109%20%E3%82%A4%E3%83%B3%E3%83%89!5e0!3m2!1sja!2sjp!4v1685066791974!5m2!1sja!2sjp"
            id="india"
          />
        </FadeInBottom>
      </FlexColumn>
      <Spacer height="min(237px, 16.458vw)" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: "Noto Sans", sans-serif;
  font-family: "Noto Sans JP", sans-serif;
  color: #4f4f4f;

  .title {
    width: 80%;
    margin: 0 auto;

    &-text {
      font-size: min(1.111vw, 16px);
    }
  }

  .ceo-wrapper {
    margin-left: 12.917vw;
    margin-right: 8.472vw;

    .ceo {
      align-items: flex-start;
      justify-content: center;

      &-image {
        //width: 36.181vw;
        max-width: 400px;
        margin-top: min(11px, 0.764vw);
      }

      &-text {
        font-family: "Yu Gothic Pr6N";
        font-weight: 700;
        line-height: 160%;
        max-width: 400px;
        letter-spacing: 0.03em;

        &-title {
          font-size: min(14px, 0.972vw);
        }

        &-name-jpn {
          font-size: min(28px, 1.944vw);
          margin-top: min(11px, 0.764vw);
        }

        &-name-en {
          color: #828282;
          font-size: min(18px, 1.25vw);
          margin-top: min(11px, 0.764vw);
        }

        &-career {
          margin-top: min(20px, 1.389vw);
          font-size: min(14px, 0.972vw);
          font-weight: 600;
          line-height: 200%;
          white-space: nowrap;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        .ceo-image {
          width: 50vw;
        }

        &-text {
          line-height: 2;
          width: 80vw;

          &-career {
            white-space: pre-wrap;
          }

          h1 {
            display: inline-block;
            text-align: left;
          }

          ul {
            margin-top: 10px;

            li {
              text-align: left;
            }
          }
        }
      }
    }
  }

  .about {
    background-color: #f2f2f2;

    &-left {
      margin-top: min(81px, 5.625vw);
      margin-bottom: min(93px, 6.458vw);
    }

    &-info {
      font-family: "Noto Sans", sans-serif;
      font-family: "Noto Sans JP", sans-serif;
    }

    .company-info-table {
      font-family: "Yu Gothic Pr6N";
      font-weight: 600;
      font-size: min(15px, 1.042vw);
      letter-spacing: 0.03em;
      color: #4f4f4f;
      white-space: nowrap;

      tr {
        height: min(2.778vw, 40px);
      }

      .space {
        height: min(1.181vw, 17px);
      }

      .table-column-1 {
      }

      .table-column-2 {
        padding-left: 27px;
      }

      @media screen and (max-width: 768px) {
      }
    }

    .info-head {
      color: #333333;
      font-size: min(23px, 1.597vw);
      margin-top: min(91px, 6.319vw);
      margin-bottom: min(50px, 3.472vw);
    }

    &-logo {
      border: 3px #bdbdbd solid;
      padding-top: min(40px, 2.778vw);
      padding-bottom: min(26px, 1.806vw);
      padding-left: min(30px, 3.889vw);
      padding-right: min(37px, 2.569vw);

      @media screen and (max-width: 768px) {
        width: 70vw;
        margin: 0 auto;
        margin-bottom: min(93px, 6.458vw);
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      margin: 0 auto;

      &-left {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-logoIMG {
        width: 20vw;
        min-width: 150px;
      }
    }
  }
`;

export default Company;
